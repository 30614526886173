import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { NavigationMenu } from "components/NavigationMenu";
import { AcfLink, MediaItem, MenuItem } from "graphql";
import { Url } from "next/dist/shared/lib/router/router";
import Image from "next/image";
import Link from "next/link";
// import Logo from "public/logo.svg";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaYoutube } from "react-icons/fa";

export interface FooterProps {
  logo: MediaItem;
  menuItems: MenuItem[];
  facebookUrl: AcfLink;
  instagramUrl: AcfLink;
  twitterUrl: AcfLink;
  youtubeUrl: AcfLink;
}

const Footer = ({
  logo,
  menuItems,
  facebookUrl,
  instagramUrl,
  twitterUrl,
  youtubeUrl,
}: FooterProps) => {
  const year = new Date().getFullYear();

  return (
    <footer className={`bg-primary font-sans text-slate`}>
      <div
        className={`container mx-auto flex flex-col flex-wrap px-4 md:px-8 py-8 justify-center items-center`}
      >
        {/* Logo */}
        <div
          className={`flex flex-row flex-wrap items-center justify-center md:justify-start max-w-full`}
          //  sm:ml-[-70px]
        >
          {logo ? (
            <FeaturedImage image={logo} className={`mb-6 w-72`} />
          ) : (
            <a
              href="https://noisytrumpet.com"
              className="title-font mb-4 flex items-center font-medium text-slate md:mb-0"
            >
              {`SuperHero Comic Con 2024`}
            </a>
          )}
        </div>
        {/* Menu */}
        <div
          className={`flex max-[320px]:flex-col flex-row flex-wrap items-center justify-center md:justify-start`}
        >
          {/* <NavigationMenu menuItems={menuItems} className={`text-slate`} /> */}
          {menuItems.map(({ path, label }) => (
            <Link
              key={path}
              href={path ?? "/"}
              className={`max-[320px]:mr-0 mr-5 tracking-widest font-heading text-slate hover:text-secondary`}
            >
              {label}
            </Link>
          ))}
        </div>
        {/* Socials */}
        <div className={`mt-6 flex flex-row gap-4 items-center justify-center`}>
          <Link href={facebookUrl?.url ?? ""} target={`_blank`}>
            <FaFacebookF className={`text-xl hover:text-secondary`} />
          </Link>
          <Link href={instagramUrl?.url ?? ""} target={`_blank`}>
            <BsInstagram className={`text-xl hover:text-secondary`} />
          </Link>
          <Link href={twitterUrl?.url ?? ""} target={`_blank`}>
            <BsTwitter className={`text-xl hover:text-secondary`} />
          </Link>
          <Link href={youtubeUrl?.url ?? ""} target={`_blank`}>
            <FaYoutube className={`text-xl hover:text-secondary`} />
          </Link>
        </div>
        {/* Copyright / Privacy Policy */}
        <div
          className={`mt-6 flex flex-col gap-4 items-center justify-center font-body text-center uppercase`}
        >
          <p className={`mb-0`}>
            <Link
              className={`hover:text-secondary`}
              href={"/privacy-policy"}
              target={``}
            >
              Privacy Policy
            </Link>{" "}
            /{" "}
            <Link
              className={`hover:text-secondary`}
              href={"/code-of-conduct"}
              target={``}
            >
              Code of Conduct
            </Link>{" "}
            /{" "}
            <Link
              className={`hover:text-secondary`}
              href={"/show-information-and-disclaimers"}
              target={``}
            >
              Show Information & Disclaimers
            </Link>
          </p>
          <p className={`mb-0`}>
            Site by{" "}
            <Link
              className={`hover:text-secondary`}
              href={"https://noisytrumpet.com"}
              target={`_blank`}
            >
              Noisy Trumpet Communications
            </Link>
          </p>
          <p className={``}>{`©${year} PMX Events. All Rights Reserved.`}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
