import { gql } from "@apollo/client";
import { Button } from "components/Button";
import router, { useRouter } from "next/router";
import useMediaQuery from "utilities/useMediaQuery";
import { useEffect, useState } from "react";
import { Accordion } from "components/Accordion";
import clsx from "clsx";

interface FiltersProps {
  items: [
    {
      node: {
        name: string;
        slug: string;
      };
    },
  ];
  postTypeSlug: string;
  catSlug: string;
}

const Filters = ({ items, postTypeSlug, catSlug }: FiltersProps) => {
  const {
    query: { tag, tags },
  } = useRouter();

  const [isReady, setIsReady] = useState(false);

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const handleFilter = (slug: string) => {
    router.push({
      pathname: `/${postTypeSlug}/category/${catSlug}`,
      query: { tags: slug },
    });
    // if (tags) {
    //   const tagsArray = (tags as string).split(",");
    //   const index = tagsArray.indexOf(slug);
    //   if (index > -1) {
    //     tagsArray.splice(index, 1);
    //   } else {
    //     tagsArray.push(slug);
    //   }
    //   router.push({
    //     pathname: `/${postTypeSlug}/category/${catSlug}`,
    //     query: { tags: tagsArray.join(",") },
    //   });

    //   if (tagsArray.length === 0) {
    //     router.push({
    //       pathname: `/${postTypeSlug}/category/${catSlug}`,
    //     });
    //   }
    // } else {
    //   router.push({
    //     pathname: `/${postTypeSlug}/category/${catSlug}`,
    //     query: { tags: slug },
    //   });
    // }
  };

  const isActive = (slug: string) => {
    if (tags) {
      const tagsArray = (tags as string).split(",");
      return tagsArray.includes(slug);
    } else {
      return slug === tag;
    }
  };

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (!isReady || items.length <= 1) {
    return null;
  }

  let filtersMobile = clsx(`flex flex-col p-4 gap-2`);

  if (isMobile) {
    return (
      <div className={`mx-4 mt-4 text-xl`}>
        <Accordion
          question={"Filter by Tag"}
          isFilter
          filterClass={`mobile-filter`}
        >
          <form
            className={`${filtersMobile} pb-lg`}
            onSubmit={(e) => e.preventDefault()}
          >
            {items.map(
              ({
                node: { name, slug },
              }: {
                node: {
                  name: string;
                  slug: string;
                };
              }) => (
                <div key={`filter-${slug}`} className={`filter-${slug}`}>
                  <input
                    type="radio"
                    name="tag"
                    id={slug}
                    value={slug}
                    checked={isActive(slug)}
                    style={{
                      display: "none",
                    }}
                    onChange={() => handleFilter(slug)}
                  />
                  <Button
                    type={
                      (isActive(slug)
                        ? `text-primary-light`
                        : `text-primary`) as any
                    }
                    className={``}
                    onClick={() => handleFilter(slug)}
                  >
                    <label htmlFor={slug}>{name}</label>
                  </Button>
                </div>
              ),
            )}
          </form>
        </Accordion>
      </div>
    );
  }

  let filters = clsx(`flex flex-row p-4 gap-4 justify-center items-center`);

  return (
    <form className={`${filters} py-lg`} onSubmit={(e) => e.preventDefault()}>
      {items.map(
        ({
          node: { name, slug },
        }: {
          node: {
            name: string;
            slug: string;
          };
        }) => (
          <div key={`filter-${slug}`} className={`filter-${slug}`}>
            <input
              type="radio"
              name="tag"
              id={slug}
              value={slug}
              checked={isActive(slug)}
              style={{
                display: "none",
              }}
              onChange={() => handleFilter(slug)}
            />
            <Button
              type={
                (isActive(slug) ? `primary-light` : `primary-outline`) as any
              }
              className={``}
              onClick={() => handleFilter(slug)}
            >
              <label htmlFor={slug}>{name}</label>
            </Button>
          </div>
        ),
      )}
    </form>
  );
};

export default Filters;

Filters.fragments = {
  entry: gql`
    fragment CelebTagFragment on RootQueryToCelebrityTagConnection {
      nodes {
        name
        slug
        id
      }
    }
  `,
};
