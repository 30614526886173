import { Main } from "features/Main"; // SEO
import {
  AcfLink,
  AcfGoogleMap,
  MediaItem,
  MenuItem,
  PostTypeSeo,
  RootQueryToMenuItemConnection,
} from "graphql";
import { SEO } from "features/SEO";

import { Header, Footer } from "components";
import localFont from "next/font/local";
import {
  Inter,
  Roboto,
  Bangers,
  Caveat,
  Black_Han_Sans,
} from "next/font/google";

const inter = Inter({
  subsets: ["latin"],
  weight: ["300", "400", "700", "900"],
  variable: "--font-sans",
});
const roboto = Roboto({
  subsets: ["latin"],
  weight: ["300", "400", "500", "700", "900"],
  variable: "--font-body",
});
const bangers = Bangers({
  subsets: ["latin"],
  weight: ["400"],
  variable: "--font-heading",
});
const avenger = localFont({
  src: "../../pages/AVENGER.woff2",
  variable: "--font-wills",
});
const caveat = Caveat({
  subsets: ["latin"],
  weight: ["400", "500", "700"],
  variable: "--font-sign",
});
export interface LayoutProps {
  children: React.ReactNode;
  headerMenuItems: RootQueryToMenuItemConnection;
  footerMenuItems: RootQueryToMenuItemConnection;
  title: string;
  description: string;
  logo: MediaItem;
  seo: PostTypeSeo;
  ctaLink: AcfLink;
  locationName: string;
  location: AcfGoogleMap;
  email: AcfLink;
  facebookUrl: AcfLink;
  instagramUrl: AcfLink;
  twitterUrl: AcfLink;
  youtubeUrl: AcfLink;
  countDownDate: string;
  dates: any[];
  turnOffCountdown: boolean;
  turnOffDates: boolean;
  ticketsOnSale: boolean;
  generalTickets: AcfLink;
  isHomePage: boolean;
}

const Layout = ({
  children,
  headerMenuItems,
  footerMenuItems,
  ctaLink,
  title,
  description,
  seo,
  logo,
  locationName,
  location,
  facebookUrl,
  instagramUrl,
  twitterUrl,
  youtubeUrl,
  countDownDate,
  turnOffCountdown,
  turnOffDates,
  dates,
  ticketsOnSale,
  generalTickets,
  isHomePage,
}: LayoutProps) => {
  return (
    <>
      <SEO seo={seo} />
      <div
        className={`${inter.variable} ${roboto.variable} ${bangers.variable} ${caveat.variable} ${avenger.variable}`}
      >
        <Header
          isHomePage={isHomePage}
          menuItems={headerMenuItems.nodes}
          logo={logo}
          ctaLink={ctaLink}
          countDownDate={countDownDate}
          turnOffCountdown={turnOffCountdown}
          turnOffDates={turnOffDates}
          dates={dates}
          locationName={locationName}
          location={location}
          ticketsOnSale={ticketsOnSale}
          generalTickets={generalTickets}
        />
        <Main className={`main overflow-hidden`}>{children}</Main>
        <Footer
          menuItems={footerMenuItems.nodes}
          logo={logo}
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
          twitterUrl={twitterUrl}
          youtubeUrl={youtubeUrl}
        />
      </div>
    </>
  );
};

export default Layout;
