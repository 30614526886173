import { useQuery, gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { useRouter } from "next/router";
import { Layout } from "features";
import { NavigationMenu, Form, Hero, CelebSingle } from "components";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
  CELEB_INFO_FRAGMENT,
} from "fragments";

export default function Component(props) {
  const { data, loading, error } = props;

  if (loading) {
    return <Loading type="page" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const {
    celebrity,
    headerMenuItems,
    footerMenuItems,
    pMXEventsSiteSettings: siteSettings,
  } = data;
  const { seo, title } = celebrity;
  const {
    location,
    locationName,
    logo,
    socialLinks,
    dates,
    ctaLink,
    countDownDate,
    turnOffCountdown,
    turnOffDates,
    ticketsOnSale,
    generalTickets,
  } = siteSettings.siteSettings;
  const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } = socialLinks;

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo.node}
      ctaLink={ctaLink}
      turnOffCountdown={turnOffCountdown}
      countDownDate={countDownDate}
      turnOffDates={turnOffDates}
      dates={dates}
      locationName={locationName}
      location={location}
      facebookUrl={facebookUrl}
      instagramUrl={instagramUrl}
      twitterUrl={twitterUrl}
      youtubeUrl={youtubeUrl}
      ticketsOnSale={ticketsOnSale}
      generalTickets={generalTickets}
    >
      <CelebSingle celebrity={celebrity} siteSettings={siteSettings} />
      <Form
        className={``}
        sectionId={`newsletter`}
        title={`GET THE LATEST SHOW UPDATES`}
        form={`6`}
        bgImage={`superhero-starburst`}
        backgroundColor={`white`}
      />
    </Layout>
  );
}

Component.query = gql`
  ${NavigationMenu.fragments.entry}
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
  ${CELEB_INFO_FRAGMENT}
  query GetCelebrity(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $asPreview: Boolean = false
  ) {
    celebrity(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      ...CelebInfoFragment
      seo {
        twitterTitle
        twitterImage {
          mediaItemUrl
        }
        twitterDescription
        title
        schema {
          raw
          pageType
          articleType
        }
        readingTime
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphImage {
          mediaItemUrl
        }
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
        fullHead
        focuskw
        cornerstone
        canonical
        breadcrumbs {
          text
          url
        }
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    pMXEventsSiteSettings {
      ...SiteSettingsFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
