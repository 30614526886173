import { gql } from "@apollo/client";
import { FlexibleContentBlocksTabsLayout } from "graphql";
import TabContent from "./Fragments/TabContent";
import { SplitContent, TextImage, TextBlock, FAQ } from "components";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { useState, useId, useEffect } from "react";
import { motion, m } from "framer-motion";
import clsx from "clsx";

interface TabsBlockProps extends FlexibleContentBlocksTabsLayout {
  classname?: string;
  index?: number;
}

const tabContentVariant = {
  active: {
    display: "block",
    scale: 1,
  },
  inactive: {
    scale: 0.1,
    display: "none",
  },
};

const TabsBlock = ({ tabsRepeater, index }: TabsBlockProps) => {
  const tab = tabsRepeater && tabsRepeater[0];

  const tabCount = tabsRepeater && tabsRepeater.length;

  let tabClass = "";

  if (tabCount === 1) {
    tabClass = "grid-cols-1";
  } else if (tabCount === 2) {
    tabClass = "grid-cols-2 md:grid-cols-2";
  } else if (tabCount === 3) {
    tabClass = "grid-cols-2 md:grid-cols-3";
  } else if (tabCount === 4) {
    tabClass = "grid-cols-2 md:grid-cols-4";
  } else if (tabCount === 5) {
    tabClass = "grid-cols-2 md:grid-cols-5";
  } else if (tabCount === 6) {
    tabClass = "grid-cols-2 md:grid-cols-6";
  } else if (tabCount === 7) {
    tabClass = "grid-cols-2 md:grid-cols-7";
  } else if (tabCount === 8) {
    tabClass = "grid-cols-2 md:grid-cols-8";
  }

  const [selectedTab, setSelectedTab] = useState(tab?.tabLabel);

  return (
    <section className={clsx(`p-2 mdl:p-6 max-w-screen-2xl mx-auto`)}>
      <div className={`border-b-2 border-primary pb-4`}>
        <div
          className={`mx-auto w-fit grid ${tabClass}`}
        >
          {tabsRepeater &&
            tabsRepeater.map((tab, index) => {
              const tabLabel = tab?.tabLabel;
              return (
                <motion.button
                  key={`${tabLabel}-${index}`}
                  onClick={() => setSelectedTab(tabLabel)}
                  className={`${
                    tabLabel === selectedTab
                      ? "active text-white"
                      : "inactive hover:text-primary-light"
                  } relative px-3 py-1.5 text-xl font-body text-primary transition`}
                >
                  {tabLabel === selectedTab && (
                    <motion.span
                      className="absolute inset-0 bg-primary-light rounded-md"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.3,
                        type: "spring",
                      }}
                    />
                  )}
                  <span className={`relative z-10 font-bold`}>{tabLabel}</span>
                </motion.button>
              );
            })}
        </div>
      </div>
      {tabsRepeater &&
        tabsRepeater.map((tab, index) => {
          const blocks = tab?.tabContentBlocks;
          const tabLabel = tab?.tabLabel;
          return (
            <motion.div
              layout
              role="tabpanel"
              id={`${tabLabel}`}
              key={selectedTab ? `${tabLabel}-${index}` : `empty`}
              className={`${
                tabLabel === selectedTab ? `block` : `hidden`
              } mt-4`}
              variants={tabContentVariant}
              animate={tabLabel === selectedTab ? "active" : "inactive"}
              transition={{
                duration: 0.3,
                type: "spring",
              }}
            >
              <TabContent keyName={`${tabLabel}-${index}`} blocks={blocks} />
            </motion.div>
          );
        })}
    </section>
  );
};

export default TabsBlock;

TabsBlock.fragments = {
  entry: gql`
    fragment TabsBlockFragment on FlexibleContentBlocksTabsLayout {
      fieldGroupName
      tabsRepeater {
        tabLabel
        tabContentBlocks {
          fieldGroupName
          ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksFaq {
            content
            title
            hasSideImage
            sideImage {
              node {
                ...MediaItemFragment
              }
            }
            items {
              answer
              question
            }
          }
          ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksSplitContent {
            __typename
            sectionTitle
            sideOneTitle
            sideOneContent
            sideTwoTitle
            sideTwoContent
            sideOneImage {
              node {
                ...MediaItemFragment
              }
            }
            sideTwoImage {
              node {
                ...MediaItemFragment
              }
            }
          }
          ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksTextImage {
            __typename
            variant
            sectionTitle
            sectionContent
            afterImageContent
            signatureName
            writersPosition
            hasAppLinks
            ctas {
              linkType
              link {
                title
                url
                target
              }
            }
            appleAppLink {
              title
              url
              target
            }
            googleAppLink {
              title
              url
              target
            }
            backgroundColor
            backgroundImage
            fontColor
            mediaRight
            sectionImage {
              node {
                ...MediaItemFragment
              }
            }
            sectionMap {
              title
              url
              target
            }
            photoGallery(first: 15) {
              nodes {
                ...MediaItemFragment
              }
            }
          }
          ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksTextBlock {
            __typename
            sectionTitle
            sectionContent
            ctas {
              linkType
              link {
                title
                url
                target
              }
            }
            backgroundColor
            backgroundImage
            fontColor
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
