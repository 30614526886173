import { gql } from "@apollo/client";
import { MEDIA_ITEM_FRAGMENT } from "fragments";

export const CELEB_INFO_FRAGMENT = gql`
  fragment CelebInfoFragment on Celebrity {
    id
    title
    uri
    guestProps {
      showInCategory
      headshot {
        node {
          ...MediaItemFragment
        }
      }
      guestDays {
        guestDate
      }
      hasBio
      imdbLink {
        title
        url
        target
      }
      bio
      ticketAnnouncement
      ticketDisclaimer
      autographBlurb
      photoOpsBlurb
      secondaryTicketBlurb
      ticketsType
      hasTicketsReady
      hasSecondaryTicketLink
      autographPrice
      photoOpPrice
      secondaryTicketType
      secondaryPrice
      customAutographLink {
        title
        url
        target
      }
      customPhotoOpsLink {
        title
        url
        target
      }
      customSecondaryLink {
        title
        url
        target
      }
      galleryImages {
        nodes {
          ...MediaItemFragment
        }
      }
    }
    celebrityTags {
      nodes {
        name
        slug
        id
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
