import category from "./category";
import tag from "./tag";
import frontPage from "./front-page";
import page from "./page";
import single from "./single";
import singleCelebrities from "./single-celebrities";
// // Taxonomies
import taxCeleb from "./taxonomy-celeb_cat";
// // Archives
import archivePosts from "./archive-post";

export default {
  category: category,
  tag,
  "front-page": frontPage,
  page,
  // // Post types
  single,
  "single-celebrities": singleCelebrities,
  // // Taxonomies
  "taxonomy-celeb_cat": taxCeleb,
  // // Archives
  "archive-post": archivePosts,
};
