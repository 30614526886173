import { gql } from "@apollo/client";
import { FlexibleContentBlocksSplitContent } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { Content } from "components/Content";
import useMediaQuery from "utilities/useMediaQuery";
interface SplitContentProps extends FlexibleContentBlocksSplitContent {
  className?: string;
  image?: any;
}

const SplitContent = ({
  className,
  sectionTitle,
  sideOneTitle,
  sideOneContent,
  sideTwoTitle,
  sideTwoContent,
  sideOneImage,
  sideTwoImage,
}: SplitContentProps) => {
  const sideOneImageNode = sideOneImage?.node;
  const sideTwoImageNode = sideTwoImage?.node;

  const isMobile = useMediaQuery("(max-width: 868px)");

  let wrapperClass = clsx(
    `flex flex-col mdl:flex-row items-center justify-between mx-auto gap-6 relative h-full max-w-screen-2xl`,
  );
  let innerWrapperClass = clsx(
    `w-full mdl:w-[350px] lg:w-[450px] min-h-[365px] px-4 mdl:px-8 flex flex-col justify-center items-center`,
  );
  let innerSectionClass = clsx(
    `w-full mdl:w-[350px] lg:w-[450px] text-center text-slate font-body text-md py-4 mdl:py-6 border-b-2 border-secondary last-of-type:border-none`,
  );
  let innerWrapperTitleClass = clsx(
    `font-heading text-4xl font-bold text-center text-slate tracking-widest uppercase mb-4`,
  );
  let sideImgClass = clsx(
    `relative flex -mt-4 mdl:mt-4 mdl:w-1/3 mdl:self-end`,
  );

  return (
    <section className={`${className ? className : ``} bg-primary`}>
      {sectionTitle && (
        <div className={`bg-slate px-4 py-7`}>
          <h3
            className={`font-heading text-4xl md:text-[3.5rem] md:leading-[3.5rem] text-center font-bold tracking-widest`}
          >
            {sectionTitle}
          </h3>
        </div>
      )}
      <div className={wrapperClass}>
        {sideOneImageNode && (
          <div className={sideImgClass}>
            <FeaturedImage
              image={sideOneImageNode}
              className={`mdl:absolute mdl:bottom-0`}
            />
          </div>
        )}
        <div className={innerWrapperClass}>
          <div className={innerSectionClass}>
            {sideOneTitle && (
              <h3 className={innerWrapperTitleClass}>{sideOneTitle}</h3>
            )}
            {sideOneContent && (
              <Content
                className={`max-w-[300px] mx-auto`}
                content={sideOneContent}
              />
            )}
          </div>
          <div className={innerSectionClass}>
            {sideTwoImageNode && isMobile && (
              <div className={sideImgClass}>
                <FeaturedImage
                  image={sideTwoImageNode}
                  className={`mdl:absolute mdl:bottom-0`}
                />
              </div>
            )}
            {sideTwoTitle && (
              <h3 className={`${innerWrapperTitleClass} ${isMobile && `mt-4`}`}>
                {sideTwoTitle}
              </h3>
            )}
            {sideTwoContent && (
              <Content
                className={`max-w-[300px] mx-auto`}
                content={sideTwoContent}
              />
            )}
          </div>
        </div>
        {sideTwoImageNode && !isMobile && (
          <div className={sideImgClass}>
            <FeaturedImage
              image={sideTwoImageNode}
              className={`mdl:absolute mdl:bottom-0`}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default SplitContent;

SplitContent.fragments = {
  entry: gql`
    fragment SplitContentFragment on FlexibleContentBlocksSplitContent {
      __typename
      sectionTitle
      sideOneTitle
      sideOneContent
      sideTwoTitle
      sideTwoContent
      sideOneImage {
        node {
          ...MediaItemFragment
        }
      }
      sideTwoImage {
        node {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
