import { gql } from "@apollo/client";
import { FlexibleContentBlocksFeatures } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { Content } from "components/Content";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";
import useMediaQuery from "utilities/useMediaQuery";
import removeGt from "utilities/removeGt";

const superhero = {
  sourceUrl: "/superhero.svg",
  mediaDetails: { width: 1436, height: 1050 },
  altText: "Superhero graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const starburst = {
  sourceUrl: "/starburst.svg",
  mediaDetails: { width: 511, height: 426 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const supergirl = {
  sourceUrl: "/supergirl.svg",
  mediaDetails: { width: 1360, height: 1057 },
  altText: "Supergirl graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const stars = {
  sourceUrl: "/stars-dual.svg",
  mediaDetails: { width: 847, height: 1053 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

interface FeaturesBlockProps extends FlexibleContentBlocksFeatures {
  className?: string;
}

const FeaturesBlock = ({
  className,
  content,
  subtitle,
  title,
  featuredItem,
  ctas,
  backgroundColor,
  backgroundImage,
}: FeaturesBlockProps) => {
  const isMobile = useMediaQuery("(max-width: 868px)");

  const hasContent = content ?? null;
  const isSlateBg = backgroundColor && backgroundColor[0] === "slate";
  const isDarkSlateBg = backgroundColor && backgroundColor[0] === "dark-slate";

  const noBgImage = backgroundImage && backgroundImage[0] === "none";
  const superheroStar =
    backgroundImage && backgroundImage[0] === "superhero-starburst";
  const superGirlStar =
    backgroundImage && backgroundImage[0] === "supergirl-stars";
  const starburstTleft =
    backgroundImage && backgroundImage[0] === "starburst-t-left";
  const starburstBleft =
    backgroundImage && backgroundImage[0] === "starburst-b-left";
  const starburstTright =
    backgroundImage && backgroundImage[0] === "starburst-t-right";
  const starburstBright =
    backgroundImage && backgroundImage[0] === "starburst-b-right";
  const starburstDual =
    backgroundImage && backgroundImage[0] === "starburst-dual";

  let wrapperClass = clsx(
    `grid bg-${backgroundColor} overflow-hidden relative`,
    isMobile && `pb-4`,
  );
  let innerWrapperClass = clsx(
    `flex flex-col items-center justify-between mx-auto gap-6 py-6 relative h-full w-full max-w-screen-2xl overflow-hidden z-10`,
    hasContent ? `mdl:flex-row mdl:max-h-[530px]` : ``,
  );

  let secTitleClass = clsx(
    `w-full py-2 px-4 mdl:px-2 mdl:px-0 mx-auto mdl:ml-8`,
    hasContent ? `mdl:max-w-[460px]` : ``,
  );
  let titleClass = clsx(
    `font-heading text-3xl mdl:text-[3.5rem] leading-none mdl:leading-[3.3rem] font-bold text-center tracking-widest`,
    hasContent ? `mdl:text-left` : ``,
    isSlateBg ? `text-primary` : `text-slate`,
    isDarkSlateBg && `drop-shadow-primary`,
  );
  let subTitleClass = clsx(
    `font-body text-xl text-secondary tracking-wider mt-4 mb-6`,
  );
  let secContentClass = clsx(
    `font-body text-md tracking-wider mt-4 mb-6 max-[320px]:w-full max-w-[280px] mx-auto mdl:mx-0 mdl:max-w-full`,
    isSlateBg ? `text-primary` : `text-slate`,
    isDarkSlateBg && `drop-shadow-primary`,
  );

  let featItemScroll = clsx(
    `custom-scrollbar flex flex-col items-center justify-center overflow-x-auto`,
    hasContent ? `w-full mdl:max-w-[60%]` : `w-full max-w-screen-xl`,
    isMobile && `mb-4`,
  );
  let featItemScrollInner = clsx(
    `flex flex-row items-stetch w-full gap-2 mdl:gap-4 py-4`,
  );
  let featItemClass = clsx(
    `font-body flex flex-col gap-4 items-center justify-start text-center max-[320px]:w-[220px] w-[260px] mdl:w-[290px] h-full last-of-type:mr-6`,
    isSlateBg ? `bg-primary text-slate` : `bg-slate text-primary`,
  );

  let featItemTitleClass = clsx(
    `font-heading font-bold text-center tracking-widest uppercase`,
    isSlateBg ? `text-slate` : `text-primary`,
  );
  let featItemImgClass = clsx(`w-full max-h-[200px] overflow-hidden`);
  let featItemContentClass = clsx(`px-4 pb-4 text-center`);

  let bgItemClass = clsx(`absolute w-full h-full z-0`);

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div id="featuredBlock" className={`block relative invisible -top-[180px]`}></div>
      <div className={innerWrapperClass}>
        <div className={secTitleClass}>
          {subtitle && <h3 className={subTitleClass}>{subtitle}</h3>}
          {title ? (
            // <h2 className={titleClass}>{title}</h2>
            <Content className={titleClass} content={title} />
          ) : null}

          {hasContent && (
            <>
              <Content className={secContentClass} content={content} />
              <div className={`flex justify-center mdl:block`}>
                {ctas &&
                  ctas.map((cta, index) => {
                    const link = cta?.link;
                    const isPrimary = cta?.type && cta?.type[0] === "primary";
                    const isSecondary =
                      cta?.type && cta?.type[0] === "secondary";

                    let btnType = clsx(
                      isSecondary &&
                        `rounded-md bg-secondary border-2 border-secondary py-2 px-4 font-bold text-white text-center font-sans uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider`,
                      isPrimary && // Primary-outline styling
                        `bg-primary-light rounded-md border-2 border-primary-light py-2 px-4 font-bold text-white text-center font-sans uppercase hover:bg-white hover:text-primary-light hover:border-primary-light transition duration-300 ease-in-out tracking-wider`,
                    );

                    return (
                      <Link
                        key={`${index}-${cta}`}
                        href={link?.url ?? ""}
                        target={link?.target ?? "_self"}
                        className={btnType}
                      >
                        {link?.title}
                      </Link>
                    );
                  })}
              </div>
            </>
          )}
        </div>
        <div className={featItemScroll}>
          <div className={featItemScrollInner}>
            {featuredItem &&
              featuredItem.map((item, index) => {
                const title = item?.title;
                const content = item?.content;
                const link = item?.link;
                const linkType = item?.linkType;
                const featuredPhoto = item?.featuredPhoto?.node;

                let featItemBtnClass = clsx(
                  isSlateBg ? `border-primary` : `border-slate`,
                  `relative`,
                  `before:absolute before:w-full before:h-[14px] before:top-0 before:z-10 hover:before:bg-secondary before:transition before:duration-300 before:ease-in-out after:absolute after:w-full after:h-[14px] after:bottom-0 after:z-10 hover:after:bg-secondary after:transition after:duration-300 after:ease-in-out`,
                );

                let titleLength = title?.length ?? 0;
                let textSize =
                  titleLength >= 18
                    ? `text-lg mdl:text-xl`
                    : `text-2xl mdl:text-3xl`;

                return link ? (
                  linkType ? (
                    <div
                      key={`${index}-${item}`}
                      className={`${featItemClass} ${featItemBtnClass} ${
                        title ? `pt-[14px]` : ``
                      }`}
                    >
                      {title && (
                        <div
                          className={`h-[38px] flex justify-center items-center`}
                        >
                          <h3 className={`${featItemTitleClass} ${textSize}`}>
                            {title}
                          </h3>
                        </div>
                      )}
                      {featuredPhoto && (
                        <div className={featItemImgClass}>
                          <FeaturedImage image={featuredPhoto} />
                        </div>
                      )}
                      <Link
                        href={link?.url ?? ""}
                        target={link?.target ?? "_self"}
                        className={`max-[320px]:w-[220px] w-[260px] mdl:w-[290px] pb-[14px]`}
                      >
                        {content ? (
                          <Content
                            className={featItemContentClass}
                            content={content}
                          />
                        ) : (
                          <span className={`font-body text-2xl`}>
                            {link?.title && removeGt(link?.title)}
                          </span>
                        )}
                      </Link>
                    </div>
                  ) : (
                    <Link
                      key={`${index}-${item}`}
                      href={link?.url ?? ""}
                      target={link?.target ?? "_self"}
                      className={`max-[320px]:w-[220px] w-[260px] mdl:w-[290px]`}
                    >
                      <div
                        className={`${featItemClass} ${featItemBtnClass} ${
                          title ? `pt-[14px]` : ``
                        }`}
                      >
                        {title && (
                          <div
                            className={`h-[38px] flex justify-center items-center`}
                          >
                            <h3 className={`${featItemTitleClass} ${textSize}`}>
                              {title}
                            </h3>
                          </div>
                        )}
                        {featuredPhoto && (
                          <div className={featItemImgClass}>
                            <FeaturedImage image={featuredPhoto} />
                          </div>
                        )}

                        <Content
                          className={featItemContentClass}
                          content={content}
                        />
                      </div>
                    </Link>
                  )
                ) : (
                  <div
                    key={`${index}-${item}`}
                    className={`max-[320px]:w-[220px] w-[260px] mdl:w-[290px]`}
                  >
                    <div
                      className={`${featItemClass} ${title ? `pt-[14px]` : ``}`}
                    >
                      {title && (
                        <div
                          className={`h-[38px] flex justify-center items-center`}
                        >
                          <h3 className={`${featItemTitleClass} ${textSize}`}>
                            {title}
                          </h3>
                        </div>
                      )}
                      {featuredPhoto && (
                        <div className={featItemImgClass}>
                          <FeaturedImage image={featuredPhoto} />
                        </div>
                      )}

                      <Content
                        className={featItemContentClass}
                        content={content}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {!hasContent && (
          <div className={``}>
            {ctas &&
              ctas.map((cta, index) => {
                const link = cta?.link;
                const isPrimary = cta?.type && cta?.type[0] === "primary";
                const isSecondary = cta?.type && cta?.type[0] === "secondary";

                let btnType = clsx(
                  isPrimary &&
                    `rounded-md bg-secondary border-2 border-secondary py-2 px-4 font-bold text-white font-sans text-center uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider`,
                  isSecondary &&
                    `bg-white rounded-md border-2 border-secondary py-2 px-4 font-bold text-secondary font-sans text-center uppercase hover:bg-secondary hover:text-white hover:border-secondary transition duration-300 ease-in-out tracking-wider`,
                );

                return (
                  <Link
                    key={`${index}-${cta}`}
                    href={link?.url ?? ""}
                    target={link?.target ?? "_self"}
                    className={btnType}
                  >
                    {link?.title}
                  </Link>
                );
              })}
          </div>
        )}
      </div>
      {!noBgImage && (
        <div className={bgItemClass} style={{ gridArea: "1/1" }}>
          {superGirlStar && (
            <>
              <FeaturedImage
                className={`absolute hidden mdl:block bottom-0 -right-52 w-[45rem] opacity-50`}
                image={supergirl}
              />
              <FeaturedImage
                className={`absolute top-8 left-6 w-[22rem] opacity-50`}
                image={stars}
              />
            </>
          )}
          {superheroStar && (
            <>
              <FeaturedImage
                className={`absolute top-4 -left-52 w-[45rem]`}
                image={superhero}
              />
              <FeaturedImage
                className={`absolute bottom-12 right-6 w-[20rem]`}
                image={starburst}
              />
            </>
          )}
          {starburstDual && (
            <>
              <FeaturedImage
                className={`absolute top-2 -left-40 -scale-x-100 w-[25rem]`}
                image={starburst}
              />
              <FeaturedImage
                className={`absolute bottom-2 -right-40 -scale-y-100 w-[25rem]`}
                image={starburst}
              />
            </>
          )}
          {(starburstBright ||
            starburstTright ||
            starburstBleft ||
            starburstTleft) && (
            <FeaturedImage
              className={`absolute ${starburstBright && `bottom-2 -right-40`} ${
                starburstTright && `top-2 -right-40`
              } ${
                starburstBleft && `bottom-2 -left-40 -scale-x-100 -scale-y-100`
              } ${starburstTleft && `top-2 -left-40 -scale-x-100`}`}
              image={starburst}
            />
          )}
        </div>
      )}
    </section>
  );
};

export default FeaturesBlock;

FeaturesBlock.fragments = {
  entry: gql`
    fragment FeaturesBlockFragment on FlexibleContentBlocksFeatures {
      __typename
      subtitle
      title
      content
      backgroundColor
      backgroundImage
      ctas {
        link {
          title
          url
          target
        }
        type
      }
      featuredItem {
        title
        content
        linkType
        link {
          title
          url
          target
        }
        featuredPhoto {
          node {
            ...MediaItemFragment
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
