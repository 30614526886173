import { FlexibleContentBlocksTabsLayout } from "graphql";

import clsx from "clsx";
import { SplitContent, TextImage, TextBlock, TabsBlock, FAQ } from "components";

interface TabsBlockProps extends FlexibleContentBlocksTabsLayout {
  keyName?: string;
  blocks: any;
}

const TabContent = ({ blocks, keyName }: TabsBlockProps) => {
  return (
    <>
      {blocks &&
        blocks.map((block: any) => {
          const prefix =
            "FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocks";

          // @ts-ignore
          const { fieldGroupName } = block;

          let name = fieldGroupName && fieldGroupName.replace(prefix, "");

          switch (name) {
            // Hero
            case "SplitContent": {
              return (
                <SplitContent
                  key={keyName}
                  sectionTitle={block?.sectionTitle}
                  sideOneTitle={block?.sideOneTitle}
                  sideOneContent={block?.sideOneContent}
                  sideTwoTitle={block?.sideTwoTitle}
                  sideTwoContent={block?.sideTwoContent}
                  sideOneImage={block?.sideOneImage}
                  sideTwoImage={block?.sideTwoImage}
                />
              );
            }
            // Text Image
            case "TextImage": {
              return (
                <TextImage
                  key={keyName}
                  variant={block?.variant}
                  sectionTitle={block?.sectionTitle}
                  sectionContent={block?.sectionContent}
                  hasAppLinks={block?.hasAppLinks}
                  ctas={block?.ctas}
                  appleAppLink={block?.appleAppLink}
                  googleAppLink={block?.googleAppLink}
                  backgroundColor={block?.backgroundColor}
                  backgroundImage={block?.backgroundImage}
                  fontColor={block?.fontColor}
                  mediaRight={block?.mediaRight}
                  sectionImage={block?.sectionImage}
                  sectionMap={block?.sectionMap}
                  photoGallery={block?.photoGallery}
                  afterImageContent={block?.afterImageContent}
                  signatureName={block?.signatureName}
                  writersPosition={block?.writersPosition}
                />
              );
            }
            // Text Image
            case "TextBlock": {
              return (
                <TextBlock
                  key={keyName}
                  sectionTitle={block?.sectionTitle}
                  sectionContent={block?.sectionContent}
                  ctas={block?.ctas}
                  backgroundColor={block?.backgroundColor}
                  backgroundImage={block?.backgroundImage}
                  fontColor={block?.fontColor}
                />
              );
            }
            // Faq
            case "Faq": {
              return (
                <FAQ
                  key={keyName}
                  title={block?.title}
                  content={block?.content}
                  hasSideImage={block?.hasSideImage}
                  sideImage={block?.sideImage}
                  items={block?.items}
                />
              );
            }

            default: {
              return null;
            }
          }
        })}
    </>
  );
};

export default TabContent;

// TabsBlock.fragments = {
//   entry: gql`
//     fragment TabsBlockFragment on FlexibleContentBlocksTabsLayout {
//       fieldGroupName
//       tabsRepeater {
//         tabLabel
//         tabContentBlocks {
//           fieldGroupName
//           ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksFaq {
//             content
//             title
//             hasSideImage
//             sideImage {
//               node {
//                 ...MediaItemFragment
//               }
//             }
//             items {
//               answer
//               question
//             }
//           }
//           ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksSplitContent {
//             __typename
//             sectionTitle
//             sideOneTitle
//             sideOneContent
//             sideTwoTitle
//             sideTwoContent
//             sideOneImage {
//               node {
//                 ...MediaItemFragment
//               }
//             }
//             sideTwoImage {
//               node {
//                 ...MediaItemFragment
//               }
//             }
//           }
//           ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksTextImage {
//             __typename
//             variant
//             sectionTitle
//             sectionContent
//             afterImageContent
//             signatureName
//             writersPosition
//             hasAppLinks
//             ctas {
//               linkType
//               link {
//                 title
//                 url
//                 target
//               }
//             }
//             appleAppLink {
//               title
//               url
//               target
//             }
//             googleAppLink {
//               title
//               url
//               target
//             }
//             backgroundColor
//             backgroundImage
//             fontColor
//             mediaRight
//             sectionImage {
//               node {
//                 ...MediaItemFragment
//               }
//             }
//             sectionMap {
//               title
//               url
//               target
//             }
//             photoGallery(first: 15) {
//               nodes {
//                 ...MediaItemFragment
//               }
//             }
//           }
//           ... on FlexibleContentBlocksTabsLayoutTabsRepeaterTabContentBlocksTextBlock {
//             __typename
//             sectionTitle
//             sectionContent
//             ctas {
//               linkType
//               link {
//                 title
//                 url
//                 target
//               }
//             }
//             backgroundColor
//             backgroundImage
//             fontColor
//           }
//         }
//       }
//     }
//     ${MEDIA_ITEM_FRAGMENT}
//   `,
// };
