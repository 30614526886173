import { useQuery, gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { useRouter } from "next/router";
import { Layout } from "features";
import { NavigationMenu, Form, Hero } from "components";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
} from "fragments";

export default function Component(props) {
  const { data, loading, error } = props;

  if (loading) {
    return <Loading type="page" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const {
    page,
    headerMenuItems,
    footerMenuItems,
    pMXEventsSiteSettings: siteSettings,
  } = data;
  const { seo, title, flexibleContent } = page;
  const { blocks } = flexibleContent;
  const {
    location,
    locationName,
    logo,
    ctaLink,
    socialLinks,
    dates,
    countDownDate,
    turnOffCountdown,
    turnOffDates,
    ticketsOnSale,
    generalTickets,
  } = siteSettings.siteSettings;
  const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } = socialLinks;

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo.node}
      cta={cta}
      dates={dates}
      turnOffCountdown={turnOffCountdown}
      countDownDate={countDownDate}
      turnOffDates={turnOffDates}
      locationName={locationName}
      location={location}
      facebookUrl={facebookUrl}
      instagramUrl={instagramUrl}
      twitterUrl={twitterUrl}
      youtubeUrl={youtubeUrl}
      ticketsOnSale={ticketsOnSale}
      generalTickets={generalTickets}
    >
      <Hero type={`basic-hero`} title={`Category: ${name}`} />
      <Container>
        {posts.edges.map((post) => (
          <Post
            title={post.node.title}
            content={post.node.content}
            date={post.node.date}
            author={post.node.author?.node.name}
            uri={post.node.uri}
            featuredImage={post.node.featuredImage?.node}
            key={post.node.id}
          />
        ))}
      </Container>
      <Form
        className={`py-8`}
        sectionId={`newsletter`}
        title={`GET THE LATEST SHOW UPDATES`}
        form={`6`}
        backgroundImage={`superhero-starburst`}
        backgroundColor={`white`}
      />
    </Layout>
  );
}

Component.query = gql`
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
  query GetCategoryPage(
    $uri: String!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
  ) {
    nodeByUri(uri: $uri) {
      ... on Category {
        name
        posts {
          edges {
            node {
              id
              title
              content
              date
              uri
              author {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    pMXEventsSiteSettings {
      ...SiteSettingsFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
`;

Component.variables = ({ uri }) => {
  return {
    uri,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
  };
};
