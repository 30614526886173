import { gql } from "@apollo/client";
import { FlexibleContentBlocksHero, MediaItem } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import GuestStar from "public/guest-star";
import Exhibitors from "public/exhibitors";
import Tickets from "public/tickets";
import Link from "next/link";
import Image from "next/image";
import useMediaQuery from "utilities/useMediaQuery";
import { Content } from "components/Content";
import { Button } from "components/Button";
import Schedule from "public/schedule";
interface HeroProps extends FlexibleContentBlocksHero {
  className?: string;
  image?: any;
  type?: string;
}

const Hero = ({
  className,
  heroType,
  type,
  hasOverlay,
  title,
  titleSecond,
  overlayCtas,
  image,
  backgroundVideo,
  backgroundVideoMp4,
}: HeroProps) => {
  const isBasic =
    (heroType && heroType[0] === "basic-hero") || type === "basic-hero";
  const isImage =
    (heroType && heroType[0] === "img-hero") || type === "img-hero";
  const isVideo =
    (heroType && heroType[0] === "video-hero") || type === "video-hero";
  const isThanks =
    (heroType && heroType[0] === "thanks-hero") || type === "thanks-hero";
  const backgroundImage = image?.node || null;
  const videoMimeType = backgroundVideo?.node?.mimeType || null;
  const videoUrl = backgroundVideo?.node?.mediaItemUrl || null;
  const mp4VideoMimeType = backgroundVideoMp4?.node?.mimeType || null;
  const mp4VideoUrl = backgroundVideoMp4?.node?.mediaItemUrl || null;
  const hasOverlayCtas = overlayCtas && overlayCtas.length > 0;

  const isMobile = useMediaQuery("(max-width: 868px)");

  let wrapperClass = clsx(
    `grid bg-primary realtive overflow-y-hidden`,
    isImage && `content-center justify-center`,
    //  max-h-[530px]
  );

  let innerWrapperClass = clsx(
    `items-center justify-center mx-auto flex flex-col py-6 z-10 relative w-full h-full max-h-[530px]`,
    hasOverlay && `bg-black/[50%]`,
  );

  let innerItemClass = clsx(
    `container flex flex-col gap-6 md:w-full`,
    hasOverlay && `md:flex-row justify-center w-[95%]`,
  );
  let bgVideoClass = clsx(
    `relative w-auto h-full z-0 grid overflow-hidden max-h-[530px]`,
  );
  let bgPhotoClass = clsx(`relative w-auto h-full z-0`);

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      {(isBasic || hasOverlay) && (
        <div className={innerWrapperClass} style={{ gridArea: "1/1" }}>
          <div className={innerItemClass}>
            {title && (
              <h1
                className={`font-heading text-4xl md:text-5xl font-bold text-center text-slate tracking-widest drop-shadow-primary ${
                  !isBasic ? `hidden` : ``
                }`}
              >
                {title}
              </h1>
            )}
            {hasOverlay && (
              <div
                className={`flex flex-col justify-center items-center gap-2 mdl:gap-4 w-full mdl:w-[45%] pl-4 pr-4 mdl:pr-8 pb-6 mdl:pb-0 mdl:border-r-4 border-slate/50`}
              >
                {/* {hasGuestCards ? (
                <>
                  <Carousel
                    items={guestCards?.nodes ?? []}
                    renderSlide={renderImageSlide}
                    numNgroups={1}
                    noDots
                  />
                </>
              ) : (
                <>
                  <Image
                    src={`/2024-ace-shcc-logo.png`}
                    alt={`SCC icon`}
                    width={400}
                    height={400}
                    className={`mb-3 w-full h-auto max-w-[250px] mdl:max-w-[400px]`}
                    priority
                  />
                  <Button
                    href={`https://checkout.growtix.com/eh/SUPERHERO_COMIC_CON_2024?_gl=1*rrctmd*_ga*MTM1MjQ0ODI3LjE2Njg1NTM1NzQ.*_ga_WFJWZNSTPH*MTY5ODQzNjkzNS4yMzMuMS4xNjk4NDM3MDAwLjYwLjAuMA..`}
                    target={`_blank`}
                    type={`secondary`}
                  >
                    Buy Passes
                  </Button>
                </>
              )} */}
              <Image
                src={`/ACE-SHCC-TJH-logo-2024.png`}
                alt={`SCC icon`}
                width={400}
                height={400}
                className={`mb-3 w-full h-auto max-w-[250px] mdl:max-w-[400px]`}
                priority
              />
              <Button
                href={`https://checkout.growtix.com/eh/SUPERHERO_COMIC_CON_2024?_gl=1*rrctmd*_ga*MTM1MjQ0ODI3LjE2Njg1NTM1NzQ.*_ga_WFJWZNSTPH*MTY5ODQzNjkzNS4yMzMuMS4xNjk4NDM3MDAwLjYwLjAuMA..`}
                target={`_blank`}
                type={`secondary`}
              >
                Buy Passes
              </Button>
              </div>
            )}
            {hasOverlayCtas && !isMobile && (
              <div
                className={`flex flex-row justify-center gap-2 mdl:gap-4 w-full mdl:w-[55%]`}
              >
                {overlayCtas.map((cta, index) => {
                  const { icon, link } = cta || {};
                  const iconType = icon && icon[0];
                  return (
                    <div key={index} className={`group`}>
                      <Link
                        href={link?.url ?? ""}
                        target={link?.target ?? "_self"}
                        className={`font-body font-bold text-center text-md mdl:text-lg uppercase tracking-widest max-w-[150px] flex flex-col justify-center items-center gap-2 text-slate group-hover:text-[#00AEEF] transition duration-300 ease-in-out`}
                      >
                        {icon && (
                          <div
                            className={`flex flex-col justify-center items-center w-full h-[100px]`}
                          >
                            {iconType === "guest-star" && (
                              <GuestStar
                                className={`m-auto w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                              />
                            )}
                            {iconType === "exhibitors" && (
                              <Exhibitors
                                className={`m-auto w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                              />
                            )}
                            {iconType === "schedule" && (
                              <Schedule
                                className={`m-auto w-full h-auto p-2 max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                              />
                            )}
                            {iconType === "tickets" && (
                              <Tickets
                                className={`m-auto w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                              />
                            )}
                            {/* <Image
                              src={`/${iconType}.svg`}
                              alt={`${iconType} icon`}
                              fillHover={`#00AEEF`}
                              width={150}
                              height={150}
                              className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px]`}
                              priority
                            /> */}
                          </div>
                        )}
                        {link?.title}
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
      {isThanks && (
        <div className={innerWrapperClass} style={{ gridArea: "1/1" }}>
          <div className={`${innerItemClass} items-end`}>
              <Image
                src={`/ACE-SHCC-TJH-logo-2024.png`}
                alt={`SCC icon`}
                width={400}
                height={400}
                className={`mb-3 w-full h-auto max-w-[250px] mdl:max-w-[400px]`}
                priority
              />
            {title && (
              <h1
                className={`font-heading text-4xl md:text-5xl font-bold text-center text-slate tracking-widest drop-shadow-primary`}
              >
                {title}
              </h1>
            )}
            {titleSecond && (
              <h2
                className={`font-heading text-4xl md:text-5xl font-bold text-center text-slate tracking-widest drop-shadow-primary`}
              >
                {titleSecond}
              </h2>
            )}
          </div>
        </div>
      )}
      {(isThanks || isImage) && backgroundImage && (
        <div className={bgPhotoClass} style={{ gridArea: "1/1" }}>
          <FeaturedImage className={``} image={backgroundImage} />
        </div>
      )}
      {isVideo && backgroundVideo && (
        <div className={`${bgVideoClass}`} style={{ gridArea: "1/1" }}>
          <video
            className={`place-self-center`}
            width={`auto`}
            height={`100%`}
            autoPlay
            playsInline
            muted
            loop
          >
            <source
              src={`${mp4VideoUrl}`}
              type={`${mp4VideoMimeType}`}
            ></source>
            <source src={`${videoUrl}`} type={`${videoMimeType}`}></source>
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {isMobile && hasOverlay && hasOverlayCtas && (
        <div className={`relative bg-primary content p-4`}>
          <div
            className={`flex max-[320px]:flex-wrap flex-row justify-center gap-2 md:gap-4 w-full mdl:w-[55%]`}
          >
            {overlayCtas.map((cta, index) => {
              const { icon, link } = cta || {};
              const iconType = icon && icon[0];
              return (
                <div key={index} className={`group`}>
                  <Link
                    href={link?.url ?? ""}
                    target={link?.target ?? "_self"}
                    className={`font-body font-bold text-center max-[320px]:text-sm text-md md:text-lg uppercase tracking-widest max-w-[150px] flex flex-col justify-center items-center gap-2 text-slate group-hover:text-[#00AEEF] transition duration-300 ease-in-out`}
                  >
                    {icon && (
                      <>
                        {iconType === "guest-star" && (
                          <GuestStar
                            className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                          />
                        )}
                        {iconType === "exhibitors" && (
                          <Exhibitors
                            className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                          />
                        )}
                        {iconType === "schedule" && (
                              <Schedule
                                className={`m-auto w-full h-auto p-2 max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                              />
                            )}
                        {iconType === "tickets" && (
                          <Tickets
                            className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                          />
                        )}
                        {/* <Image
                              src={`/${iconType}.svg`}
                              alt={`${iconType} icon`}
                              fillHover={`#00AEEF`}
                              width={150}
                              height={150}
                              className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px]`}
                              priority
                            /> */}
                      </>
                    )}
                    {link?.title}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;

Hero.fragments = {
  entry: gql`
    fragment HeroFragment on FlexibleContentBlocksHero {
      __typename
      heroType
      title
      titleSecond
      hasOverlay
      overlayCtas {
        icon
        link {
          title
          url
          target
        }
      }
      image: backgroundImage {
        node {
          ...MediaItemFragment
        }
      }
      backgroundVideo {
        node {
          altText
          mediaItemUrl
          mimeType
        }
      }
      backgroundVideoMp4 {
        node {
          altText
          mediaItemUrl
          mimeType
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
