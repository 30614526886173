import { gql } from "@apollo/client";
import { useRouter } from "next/router";
import * as MENUS from "constants/menus";
import { Layout } from "features";
import { NavigationMenu, Form, Filters, Hero, AllCelebGrid } from "components";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
} from "fragments";

export default function Component(props) {
  const {
    headerMenuItems,
    footerMenuItems,
    pMXEventsSiteSettings: siteSettings,
  } = props.data;
  const {
    location,
    locationName,
    logo,
    socialLinks,
    dates,
    ctaLink,
    countDownDate,
    turnOffCountdown,
    turnOffDates,
    ticketsOnSale,
    generalTickets,
  } = siteSettings.siteSettings;
  const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } = socialLinks;

  const { title: siteTitle, description: siteDescription } =
    props?.data?.generalSettings;

  //   Celeb data

  const {
    asPath: uri,
    query: { tags: tagsQuery },
  } = useRouter();

  const {
    name,
    slug,
    celebrities: { nodes: celebrities },
  } = props.data.nodeByUri;

  const {
    celebrityTags: { nodes: tags },
  } = props.data ?? {};

  const hasCelebs = celebrities?.length > 0;

  const tagsInCategory = celebrities
    .map((celeb) => celeb.celebrityTags?.nodes)
    .flat();

  const tagsInCategorySet = new Set(tagsInCategory.map((tag) => tag?.slug));
  const namesInCategorySet = new Set(tagsInCategory.map((tag) => tag?.name));

  const tagsInCategoryAndQuery = Array.from(
    new Set([...tagsInCategorySet, ...(tagsQuery ? tagsQuery.split(",") : [])]),
  )
    .map((slug, i) => {
      return {
        node: {
          name: namesInCategorySet.has(slug)
            ? slug
            : tags.find((tag) => tag.slug === slug)?.name,
          slug,
        },
      };
    })
    .sort((a, b) => a.node.name.localeCompare(b.node.name));

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={{
        title: `${name} | ${siteTitle}`,
        metaDesc: siteDescription,
      }}
      logo={logo.node}
      ctaLink={ctaLink}
      turnOffCountdown={turnOffCountdown}
      countDownDate={countDownDate}
      turnOffDates={turnOffDates}
      dates={dates}
      locationName={locationName}
      location={location}
      facebookUrl={facebookUrl}
      instagramUrl={instagramUrl}
      twitterUrl={twitterUrl}
      youtubeUrl={youtubeUrl}
      ticketsOnSale={ticketsOnSale}
      generalTickets={generalTickets}
    >
      <div>
        {hasCelebs ? (
          <>
            <Filters
              items={tagsInCategoryAndQuery}
              postTypeSlug="celebrities"
              catSlug={slug}
            />
            <AllCelebGrid celebs={celebrities} tags={tagsQuery} />
          </>
        ) : (
          <Hero
            title={`No ${name} Guests Found`}
            content="Please check back soon! 👀"
            heroType="subpage-hero"
            fontColor="white"
          />
        )}
      </div>
      <Form
        className={``}
        sectionId={`newsletter`}
        title={`GET THE LATEST SHOW UPDATES`}
        form={`6`}
        bgImage={`superhero-starburst`}
        backgroundColor={`white`}
      />
    </Layout>
  );
}

Component.query = gql`
  query GetCelebArhivePage(
    $uri: String!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
  ) {
    nodeByUri(uri: $uri) {
      ... on CelebrityCategory {
        name
        slug
        uri
        celebrities(first: 100) {
          nodes {
            id
            title
            uri
            celebrityTags {
              nodes {
                name
                slug
                id
              }
            }
            guestProps {
              showInCategory
              headshot {
                node {
                  ...MediaItemFragment
                }
              }
              guestDays {
                guestDate
              }
            }
          }
        }
      }
    }
    celebrityTags(first: 25) {
      ...CelebTagFragment
    }
    generalSettings {
      ...BlogInfoFragment
    }
    pMXEventsSiteSettings {
      ...SiteSettingsFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${NavigationMenu.fragments.entry}
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
  ${Filters.fragments.entry}
`;

Component.variables = ({ uri }) => {
  return {
    uri,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
  };
};
