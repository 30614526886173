import clsx from "clsx";
import { Content } from "components/Content";
import { FlexibleContentBlocksTextImage } from "graphql";
import useMediaQuery from "utilities/useMediaQuery";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";

const googleBadge = {
  sourceUrl: "/google-play-badge.png",
  mediaDetails: { width: 336, height: 100 },
  altText: "Google Play Store Badge",
  sizes: `(max-width: 336px) 100vw, 336px`,
};
const appleBadge = {
  sourceUrl: "/apple-store-badge.png",
  mediaDetails: { width: 336, height: 100 },
  altText: "Apple Store Badge",
  sizes: `(max-width: 336px) 100vw, 336px`,
};

interface BasicBlockProps extends FlexibleContentBlocksTextImage {
  className?: string;
}

const BasicBlock = ({
  className,
  variant,
  sectionTitle,
  sectionContent,
  hasAppLinks,
  ctas,
  appleAppLink,
  googleAppLink,
  fontColor,
  mediaRight,
  sectionImage,
  sectionMap,
}: BasicBlockProps) => {
  const isBasic = variant && variant[0] === "basic";
  const isMap = variant && variant[0] === "map";
  const isExtended = variant && variant[0] === "extended";

  const sideImg = sectionImage && sectionImage?.node;

  const isMobile = useMediaQuery("(max-width: 768px)");

  let innerSectionClass = clsx(
    `flex flex-col items-center justify-between mx-auto gap-8 relative h-full max-w-screen-2xl relative z-10`,
    mediaRight ? `mdl:flex-row` : `mdl:flex-row-reverse`,
    !isExtended && `px-4 md:px-8 py-7`,
  );

  let innerContentClass = clsx(
    `w-full`,
    !isExtended && `mdl:w-[60%]`,
    isExtended && `mdl:w-[50%] px-4 md:px-8 py-7`,
  );

  let innerContentTitleClass = clsx(
    `font-heading text-4xl md:text-[3.5rem] leading-none text-center font-bold tracking-widest text-${fontColor}`,
  );

  let secContentClass = clsx(
    `font-body text-md tracking-wider mt-4 mb-6 text-${fontColor}`,
  );

  let appLinksClass = clsx(
    `flex flex-col md:flex-row gap-4 max-w-[50%] mt-4 mb-8`,
  );

  let sideMediaClass = clsx(
    `w-full`,
    !isExtended && `mdl:w-[40%]`,
    isExtended && `mdl:w-[50%]`,
  );

  return (
    <div className={`${className} ${innerSectionClass}`}>
      <div className={innerContentClass}>
        {sectionTitle && (
          // <h3 className={innerContentTitleClass}>{sectionTitle}</h3>
          <Content className={innerContentTitleClass} content={sectionTitle} />
        )}
        {sectionContent && (
          <Content className={secContentClass} content={sectionContent} />
        )}

        {hasAppLinks && (
          <div className={appLinksClass}>
            {googleAppLink && (
              <Link
                href={googleAppLink?.url ?? ""}
                target={googleAppLink?.target ?? "_self"}
              >
                <FeaturedImage className={``} image={googleBadge} />
              </Link>
            )}
            {appleAppLink && (
              <Link
                href={appleAppLink?.url ?? ""}
                target={appleAppLink?.target ?? "_self"}
              >
                <FeaturedImage className={``} image={appleBadge} />
              </Link>
            )}
          </div>
        )}

        {ctas &&
          ctas.map((cta, index) => {
            const link = cta?.link;
            const isPrimary = cta?.linkType && cta?.linkType[0] === "primary";
            const isSecondary =
              cta?.linkType && cta?.linkType[0] === "secondary";

            let btnType = clsx(
              isPrimary &&
                `rounded-md bg-secondary border-2 border-secondary py-2 px-4 font-bold text-white font-sans uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider`,
              isSecondary &&
                `bg-white rounded-md border-2 border-secondary py-2 px-4 font-bold text-secondary font-sans uppercase hover:bg-secondary hover:text-white hover:border-secondary transition duration-300 ease-in-out tracking-wider`,
            );

            return (
              <Link
                key={`${index}-${cta}`}
                href={link?.url ?? ""}
                target={link?.target ?? "_self"}
                className={btnType}
              >
                {link?.title}
              </Link>
            );
          })}
      </div>
      <div className={sideMediaClass}>
        {isMap && sectionMap && (
          <iframe
            className={``}
            style={{ width: `100%`, height: `500px` }}
            loading="lazy"
            allowFullScreen
            title={`Event Map`}
            src={sectionMap.url ?? ``}
          />
        )}
        {(isBasic || isExtended) && sectionImage && (
          <FeaturedImage className={``} image={sideImg} />
        )}
      </div>
    </div>
  );
};

export default BasicBlock;
