import { gql, useQuery } from "@apollo/client";
import { FlexibleContentBlocksCtaBlocks } from "graphql";
import clsx from "clsx";
import { Content } from "components/Content";
import Carousel from "./Fragments/Carousel";
import Link from "next/link";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaYoutube } from "react-icons/fa";

interface CTABlockProps extends FlexibleContentBlocksCtaBlocks {
  className?: string;
}

const CTABlock = ({ className, variant, title, cards }: CTABlockProps) => {
  const facebookUrl = "https://www.facebook.com/SuperheroComicCon";
  const instagramUrl = "https://www.instagram.com/superherocomiccon/";
  const twitterUrl = "https://twitter.com/Superhero_CC";
  const youtubeUrl = "https://www.youtube.com/channel/UCyiVbyQyiF91DdaBKX2U9Jw";

  const isStacked = variant && variant[0] === "stacked";
  const isGrid = variant && variant[0] === "grid";
  const isCarousel = variant && variant[0] === "carousel";

  let wrapperClass = clsx(`bg-slate`);

  let innerWrapperClass = clsx(
    `flex flex-col items-center justify-center container gap-4 md:gap-0 mx-auto z-10 relative h-full max-h-[530px]`,
    isGrid ? `flex-wrap md:flex-row` : ``,
    isCarousel ? `pt-4 pb-2` : `py-6`,
  );

  let secTitleClass = clsx(`w-full bg-primary-light py-2`);
  let titleClass = clsx(
    `font-body text-2xl font-bold text-center text-slate tracking-wider`,
  );

  let cardClass = clsx(
    `font-body flex gap-4 items-center justify-center text-center text-primary px-3`,
    isStacked && `borderBetween flex-col md:flex-row`,
    isGrid ? `borderBetween flex-col w-full md:w-1/2` : `w-full`,
    isCarousel && `flex-col md:flex-row`,
  );

  let cardTitleClass = clsx(
    `font-heading font-bold text-center text-primary md:tracking-widest uppercase`,
    isStacked ? `text-xl md:text-3xl` : `text-3xl`,
  );

  let buttonClass = clsx(
    isGrid
      ? `bg-white rounded-md border-2 border-secondary py-2 px-4 font-bold text-secondary font-sans uppercase hover:bg-secondary hover:text-white hover:border-secondary transition duration-300 ease-in-out tracking-wider`
      : `rounded-md bg-secondary border-2 border-secondary py-1 px-2 md:py-2 md:px-4 text-md md:text-[16px] font-bold text-white font-sans uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider`,
  );

  const renderCTASlide = (card: any, index: number) => {
    const { title, link, socialLinks } = card || {};
    return (
      <div key={`${index}-${card}`} className={cardClass}>
        <Content className={cardTitleClass} content={title} />
        <Link
          href={link?.url ?? ""}
          target={link?.target ?? "_self"}
          className={buttonClass}
        >
          {link?.title}
        </Link>
      </div>
    );
  };

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      {isGrid && (
        <div className={secTitleClass}>
          {title ? <h2 className={titleClass}>{title}</h2> : null}
        </div>
      )}
      <div className={innerWrapperClass}>
        {isCarousel ? (
          <Carousel
            items={cards ?? []}
            renderSlide={renderCTASlide}
            numNgroups={1}
            arrows
          />
        ) : (
          <>
            {cards &&
              cards.map((card, index) => {
                const title = card?.title;
                const link = card?.link;
                const socialLinks = card?.socialLinks;

                return (
                  <div key={`${index}-${card}`} className={cardClass}>
                    <Content className={cardTitleClass} content={title} />
                    {(link || socialLinks) && (
                      <>
                        {socialLinks ? (
                          <div
                            className={`mt-2 flex flex-row gap-6 items-center justify-center`}
                          >
                            <Link href={facebookUrl} target={`_blank`}>
                              <FaFacebookF
                                className={`text-2xl hover:text-secondary`}
                              />
                            </Link>
                            <Link href={instagramUrl} target={`_blank`}>
                              <BsInstagram
                                className={`text-2xl hover:text-secondary`}
                              />
                            </Link>
                            <Link href={twitterUrl} target={`_blank`}>
                              <BsTwitter
                                className={`text-2xl hover:text-secondary`}
                              />
                            </Link>
                            <Link href={youtubeUrl} target={`_blank`}>
                              <FaYoutube
                                className={`text-2xl hover:text-secondary`}
                              />
                            </Link>
                          </div>
                        ) : (
                          <Link
                            href={link?.url ?? ""}
                            target={link?.target ?? "_self"}
                            className={buttonClass}
                          >
                            {link?.title}
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </>
        )}
      </div>
    </section>
  );
};

export default CTABlock;

CTABlock.fragments = {
  entry: gql`
    fragment CTABlockFragment on FlexibleContentBlocksCtaBlocks {
      __typename
      variant
      title
      cards {
        title
        socialLinks
        link {
          url
          title
          target
        }
      }
    }
  `,
};
