import { gql } from "@apollo/client";

export const SITE_SETTINGS_FRAGMENT = gql`
  fragment SiteSettingsFragment on PMXEventsSiteSettings {
    siteSettings {
      ctaLink {
        target
        title
        url
      }
      logo {
        node {
          ...MediaItemFragment
        }
      }
      locationName
      turnOffCountdown
      countDownDate
      turnOffDates
      dates {
        date
      }
      ticketsOnSale
      generalTickets {
        target
        title
        url
      }
      autographTickets {
        target
        title
        url
      }
      photoOpTickets {
        target
        title
        url
      }
      location {
        placeId
        streetAddress
      }
      socialLinks {
        facebookUrl {
          target
          title
          url
        }
        instagramUrl {
          target
          title
          url
        }
        twitterUrl {
          target
          title
          url
        }
        youtubeUrl {
          target
          title
          url
        }
      }
    }
  }
`;
