import { gql } from "@apollo/client";
import { FlexibleContentBlocksTextImage } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { Content } from "components/Content";
import useMediaQuery from "utilities/useMediaQuery";
import { FeaturedImage } from "components/FeaturedImage";

import BasicBlock from "./Fragments/BasicBlock";
import GalleryBlock from "./Fragments/GalleryBlock";
import LetterBlock from "./Fragments/LetterBlock";

const superhero = {
  sourceUrl: "/superhero.svg",
  mediaDetails: { width: 1436, height: 1050 },
  altText: "Superhero graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const starburst = {
  sourceUrl: "/starburst.svg",
  mediaDetails: { width: 511, height: 426 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

interface TextImageProps extends FlexibleContentBlocksTextImage {
  className?: string;
}

const TextImage = ({
  className,
  variant,
  sectionTitle,
  sectionContent,
  hasAppLinks,
  ctas,
  appleAppLink,
  googleAppLink,
  backgroundColor,
  backgroundImage,
  fontColor,
  mediaRight,
  sectionImage,
  sectionMap,
  photoGallery,
  afterImageContent,
  signatureName,
  writersPosition,
}: TextImageProps) => {
  const isBasic = variant && variant[0] === "basic";
  const isLetter = variant && variant[0] === "letter";
  const isGallery = variant && variant[0] === "gallery";
  const isMap = variant && variant[0] === "map";
  const isExtended = variant && variant[0] === "extended";

  const isMobile = useMediaQuery("(max-width: 768px)");

  let wrapperClass = clsx(
    `grid bg-${backgroundColor} items-center justify-center overflow-hidden relative`,
  );

  let bgItemClass = clsx(`absolute w-full h-full z-0`);

  const noBgImage = backgroundImage && backgroundImage[0] === "none";
  const superheroStar =
    backgroundImage && backgroundImage[0] === "superhero-starburst";
  const starburstTleft =
    backgroundImage && backgroundImage[0] === "starburst-t-left";
  const starburstBleft =
    backgroundImage && backgroundImage[0] === "starburst-b-left";
  const starburstTright =
    backgroundImage && backgroundImage[0] === "starburst-t-right";
  const starburstBright =
    backgroundImage && backgroundImage[0] === "starburst-b-right";
  const starburstDual =
    backgroundImage && backgroundImage[0] === "starburst-dual";

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      {(isBasic || isMap || isExtended) && (
        <BasicBlock
          variant={variant}
          sectionTitle={sectionTitle}
          sectionContent={sectionContent}
          hasAppLinks={hasAppLinks}
          ctas={ctas}
          appleAppLink={appleAppLink}
          googleAppLink={googleAppLink}
          fontColor={fontColor}
          mediaRight={mediaRight}
          sectionImage={sectionImage}
          sectionMap={sectionMap}
        />
      )}
      {isGallery && (
        <GalleryBlock
          sectionTitle={sectionTitle}
          sectionContent={sectionContent}
          hasAppLinks={hasAppLinks}
          ctas={ctas}
          appleAppLink={appleAppLink}
          googleAppLink={googleAppLink}
          fontColor={fontColor}
          mediaRight={mediaRight}
          photoGallery={photoGallery}
        />
      )}
      {isLetter && (
        <LetterBlock
          sectionTitle={sectionTitle}
          sectionContent={sectionContent}
          afterImageContent={afterImageContent}
          signatureName={signatureName}
          writersPosition={writersPosition}
          fontColor={fontColor}
          mediaRight={mediaRight}
          sectionImage={sectionImage}
        />
      )}
      {!noBgImage && (
        <div className={bgItemClass} style={{ gridArea: "1/1" }}>
          {superheroStar && (
            <>
              <FeaturedImage
                className={`absolute top-4 -left-52 w-[45rem]`}
                image={superhero}
              />
              <FeaturedImage
                className={`absolute bottom-12 right-6 w-[20rem]`}
                image={starburst}
              />
            </>
          )}
          {starburstDual && (
            <>
              <FeaturedImage
                className={`absolute top-2 -left-40 -scale-x-100 w-[25rem]`}
                image={starburst}
              />
              <FeaturedImage
                className={`absolute bottom-2 -right-40 -scale-y-100 w-[25rem]`}
                image={starburst}
              />
            </>
          )}
          {(starburstBright ||
            starburstTright ||
            starburstBleft ||
            starburstTleft) && (
            <FeaturedImage
              className={`absolute ${starburstBright && `bottom-2 -right-40`} ${
                starburstTright && `top-2 -right-40`
              } ${
                starburstBleft && `bottom-2 -left-40 -scale-x-100 -scale-y-100`
              } ${starburstTleft && `top-2 -left-40 -scale-x-100`}`}
              image={starburst}
            />
          )}
        </div>
      )}
    </section>
  );
};

export default TextImage;

TextImage.fragments = {
  entry: gql`
    fragment TextImageFragment on FlexibleContentBlocksTextImage {
      __typename
      variant
      sectionTitle
      sectionContent
      afterImageContent
      signatureName
      writersPosition
      hasAppLinks
      ctas {
        linkType
        link {
          title
          url
          target
        }
      }
      appleAppLink {
        title
        url
        target
      }
      googleAppLink {
        title
        url
        target
      }
      backgroundColor
      backgroundImage
      fontColor
      mediaRight
      sectionImage {
        node {
          ...MediaItemFragment
        }
      }
      sectionMap {
        title
        url
        target
      }
      photoGallery(first: 15) {
        nodes {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
