import * as React from "react";
import { SVGProps } from "react";
const Schedule = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 650 650"
    {...props}
  >
    <g data-name="Layer 1">
        <path d="M291.67 383.33h66.67c13.81 0 25-11.19 25-25s-11.19-25-25-25h-66.67c-13.81 0-25 11.19-25 25s11.19 25 25 25Zm166.66 133.34H525c13.81 0 25-11.19 25-25s-11.19-25-25-25h-66.67c-13.81 0-25 11.19-25 25s11.19 25 25 25Zm0-133.34H525c13.81 0 25-11.19 25-25s-11.19-25-25-25h-66.67c-13.81 0-25 11.19-25 25s11.19 25 25 25ZM125 516.67h66.67c13.81 0 25-11.19 25-25s-11.19-25-25-25H125c-13.81 0-25 11.19-25 25s11.19 25 25 25Zm166.67 0h66.67c13.81 0 25-11.19 25-25s-11.19-25-25-25h-66.67c-13.81 0-25 11.19-25 25s11.19 25 25 25ZM125 383.33h66.67c13.81 0 25-11.19 25-25s-11.19-25-25-25H125c-13.81 0-25 11.19-25 25s11.19 25 25 25Z"/>
        <path d="M640.01 116.72c-8.84-17.36-22.7-31.21-40.06-40.06-19.61-9.99-41.64-9.99-81.61-9.99h-1.67V25c0-13.81-11.19-25-25-25s-25 11.19-25 25v41.67H183.33V25c0-13.81-11.19-25-25-25s-25 11.19-25 25v41.67h-1.67c-39.98 0-62 0-81.62 9.99-17.36 8.85-31.21 22.7-40.06 40.06C0 136.33 0 158.36 0 198.33v320c0 39.98 0 62 9.99 81.62 8.85 17.36 22.7 31.21 40.06 40.06C69.66 650 91.68 650 131.66 650h386.67c39.98 0 62 0 81.62-9.99 17.36-8.84 31.21-22.7 40.06-40.06 9.99-19.61 9.99-41.64 9.99-81.61V198.33c0-39.98 0-62-9.99-81.62ZM600 518.33c0 32.24 0 50-4.54 58.92a41.419 41.419 0 0 1-18.21 18.21c-8.92 4.54-26.68 4.54-58.92 4.54H131.67c-32.24 0-50 0-58.92-4.54a41.419 41.419 0 0 1-18.21-18.21C50 568.33 50 550.57 50 518.33V250h550v268.33ZM600 200H50v-1.67c0-32.24 0-50 4.54-58.92a41.448 41.448 0 0 1 18.2-18.21c8.92-4.54 26.68-4.54 58.92-4.54h386.67c32.24 0 50 0 58.92 4.54a41.419 41.419 0 0 1 18.21 18.21c4.54 8.92 4.54 26.68 4.54 58.92V200Z"/>
    </g>
  </svg>
);
export default Schedule;
